$(document).ready(function() {

    // MENU MOBILE
	$('.menu-nav').click(function(e){
	    e.preventDefault();
	    $(this).toggleClass('opened');
		$('nav, header').toggleClass('opened'); 
		$('body').toggleClass('overflow');
	});
	$('.menu-item a').click(function(){
		$('nav, .menu-nav').removeClass('opened');
		$('body').removeClass('overflow');
	});

    // 100vh FIXED
	document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);

    // SLIDESHOW
    $('.slide--hero').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        
    });

    $('.slide--about').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
        responsive: [
            {
              breakpoint: 960,
              settings:  {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                centerMode: true,
                centerPadding: '10%',
              }
            },
        ]
    });

    $('.slide--projects').slick({
        slidesToShow: 3,
        infinite: false,
        dots: false,
        arrows: false,
        responsive: [
            {
              breakpoint: 960,
              settings:  {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                centerMode: true,
                centerPadding: '10%',
              }
            },
        ]
    });

    // SCROLL ACHOR
	jQuery(document).ready(function () {
		jQuery('nav a, .btn').click(function () {
			var target = jQuery(this).attr('href').split('#').pop();
			jQuery('html, body, index').animate({ scrollTop: jQuery('#' + target).offset().top}, 500);
			return false;
		});
	});

    // TABS
    $('.filter .filter-link').click(function () {
        var filter_id = $(this).attr('data-filter');
        $('.filter .filter-link').removeClass('active');
        $('.item-project').removeClass('active');
        $(this).addClass('active');
        $("#" + filter_id).addClass('active');
    });
    $('.filter .filter-link.all').click(function () {
        $('.item-project').addClass('active');
    });
   
});